import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Indicator } from './components/Indicators';
import appRoutes, { commonRoutes } from './routes';

import './App.css';
import 'react-activity/dist/react-activity.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-simple-line-icons';

const route = (route, idx) => {
  if(route.redirect)
    return <Redirect key={idx} exact={route.exact} from={route.path} to={route.redirect} />

  return route.component ? (
      <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component {...props} />)} />
  ) : (null);
};
function App() {

  let layout = 'place';
  const hostname = window.location.hostname;
  switch(hostname) {
    case 'ticket.coconutorder.com':
    case 'ticket.coconutplace.kr':
      layout = 'ticket';
      break;
    case 'pager.coconutorder.com':
    case 'pager.coconutplace.kr':
      layout = 'pager';
      break;
    case 'coconutwaiting.com':
    case 'coconutwaiting.net':
    case 'waiting.coconutplace.kr':
      layout = 'waiting';
      break;
    case 'place.coconutorder.com':
    case 'place.coconutwaiting.com':
    case 'coconutplace.kr':
    case 'coconutplace.net':
      layout = 'place';
      break;
    case 'booking.coconutorder.com':
    case 'booking.coconutplace.kr':
      layout = 'booking';
      break;
    case 'coconutorder.com':
    case 'us.coconutorder.com':
    case 'table.coconutorder.com':
    case 'order.coconutplace.kr':
      layout = 'order';
      break;
    default:
      layout = 'order';
  }
  
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<Indicator type="digital" />}>
          <Switch>
            {commonRoutes.map(route)}
            {appRoutes[layout].map(route)}
            {/* {layout === 'order' || layout === 'waiting'?<Redirect from="/" to={"/"+layout} />:null} */}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
