import React from 'react';
import { PlaceLayout, OrderLayout, WaitingLayout, TicketingLayout, MyTicketLayout } from './containers';
//import { ItemDetail, BuyTicket } from './views/Tickets';

const Login = React.lazy(() => import('./views/Login'));
const Policy = React.lazy(() => import('./views/Policy'));
const Help = React.lazy(() => import('./views/Help'));
const PageError = React.lazy(() => import('./views/Errors'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const routes = {};

routes['place'] = [
  { path: '/', exact: false, name: '플레이스', component: PlaceLayout },
];

routes['waiting'] = [
  { path: '/:view/:key', exact: true, name: '웨이팅', component: WaitingLayout },
];

routes['order'] = [
  { path: '/', exact: false, name: '주문하기', component: OrderLayout },
];
// routes['order'] = [
//   { path: '/cart', exact: true, name: '장바구니', component: OrderLayout },
//   { path: '/:view/:key', exact: true, name: '주문하기', component: OrderLayout },
// ];

routes['ticket'] = [
  { path: '/cart', exact: true, name: '장바구니', component: TicketingLayout },
  { path: '/', exact: false, name: '티켓팅', component: TicketingLayout },
];


export const commonRoutes = [
  { path: '/login', exact: true, name: '로그인', component: Login },
  { path: '/error/:code', exact: true, name: '오류', component: PageError },
  { path: '/policy/:policy', exact: true, name: '이용정책', component: Policy },
  { path: '/help', exact: true, name: '도움말', component: Help },
  { path: '/ticket/:key', exact: true, name: '마이티켓', component: MyTicketLayout },
];
/*
export const ticketRoutes = [
  { path: '/ticketing/item/:id', exact: true, name: '티켓상세', component: ItemDetail },
  { path: '/ticketing/buy/:id', exact: true, name: '티켓구매', component: BuyTicket },
];
*/

export default routes;