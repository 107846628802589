import React, { Component } from 'react';
import { Digital, Dots } from 'react-activity';

export const Indicator = ({type}) => (
    <div style={{position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)'}}>
        {type==='digital'?(<Digital color="#597EF7" />):(<Dots color="#597EF7" />)};
    </div>
);

export default class Spinner extends Component {
    

    render() {
        const { loading } = this.props;

        return (
            <div style={{position:'relative'}}>
                { loading && (
                <div
                    style={{
                    display: 'block',
                    position: 'absolute',
                    zIndex: 657,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    margin: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                    }}>
                    <Indicator type="digital" />
                </div>
                )}
                {this.props.children}
            </div>
        )
    }
}